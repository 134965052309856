
import { defineComponent } from "vue";
import Button from "primevue/button";
import * as FacebookServices from "../api/helpers/Facebook";

export default defineComponent({
  name: "Terms",
  components: { Button},
  mounted() {
    FacebookServices.pageView();
    document.title = process.env.VUE_APP_TITLE + " | Terminos y condiciones";
    window.scrollTo(0, 0);
  },
  data() {
    return {
      name: process.env.VUE_APP_TITLE,
    };
  },
});
